export const colors = {
  manheimNavy: '#003468',
  manheimNavyDark: '#001B35',
  manheimPrimaryBlue: '#005BA8',
  manheimPrimaryBlueDark: '#004986',
  manheimCeruleanLight: '#C1DFF2',
  manheimCerulean: '#2C90CC',
  manheimCeruleanDark: '#2372A2',
  manheimGoldLight: '#FFF0C3',
  manheimGold: '#FFC20E',
  manheimGoldDark: '#EBB000',
  white: '#FFFFFF',
  ash: '#EEEEEE',
  smoke: '#BABCBE',
  slate: '#6A6A6A',
  charcoal: '#4A4A4A',
  black: '#000000',
  scarletLight: '#FCCCC0',
  scarlet: '#C33A00',
  scarletDark: '#902B00',
  meadowLight: '#E3FAD1',
  meadow: '#82C250',
  jungle: '#0D8240',
  manheimSaffron: '#EB9114',
  manheimSaffronDark: '#C0650C',
  plum: '#8C1D58',
  ocean: '#00AAA8'
};
