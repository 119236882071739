import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { fetchDisclosureDocumentsByDisclosureId } from 'bmw/common/api/requests/fetchDisclosureDocumentsByDisclosureId';
import { CheckboxWithDescription } from 'bmw/common/components/CheckboxWithDescription';
import { DisclosureDocument, DisclosureDocumentType } from 'bmw/common/model/DisclosureDocument';
import * as descriptions from 'bmw/common/constants/checkBoxDescriptions';

import { Signature } from './Signature';

interface Props {
  disclosureId: string;
  isPreterm: boolean;
}

export const AcknowledgmentSection: React.FC<Props> = ({ disclosureId, isPreterm }) => {
  const [disclosureDocument, setDisclosureDocument] = useState<DisclosureDocument>();

  useEffect(() => {
    const fetchAcknowledgmentData = async () => {
      const disclosureDocuments = await fetchDisclosureDocumentsByDisclosureId(disclosureId || '');
      const disclosureDocument = disclosureDocuments.find(
        document => document.type === DisclosureDocumentType.LeaseEndInspectionForm
      );

      setDisclosureDocument(disclosureDocument);
    };
    fetchAcknowledgmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AcknowledgmentContainer>
      {disclosureDocument && (
        <>
          <CheckboxWithDescription
            checked={disclosureDocument.dmeAcknowledgement}
            description={
              isPreterm
                ? descriptions.PRETERM_ACKNOWLEDGEMENT_CHECKBOX_DESCRIPTION
                : descriptions.ACKNOWLEDGEMENT_CHECKBOX_DESCRIPTION
            }
          />
          <CheckboxWithDescription
            checked={disclosureDocument.alterationsAddlCharges}
            description={
              isPreterm
                ? descriptions.PRETERM_CUSTOMER_CERTIFICATION_CHECKBOX_DESCRIPTION
                : descriptions.CUSTOMER_CERTIFICATION_CHECKBOX_DESCRIPTION
            }
          />
        </>
      )}
      <Signature
        signature={disclosureDocument?.clientSignature.replace('\n', '') || ''}
        signatureTitle="CUSTOMER"
        signatureDate={disclosureDocument?.clientSignatureDate || ''}
      />
      {disclosureDocument && !isPreterm && (
        <CheckboxWithDescription
          checked={disclosureDocument.personalData}
          description={descriptions.INSPECTOR_CERTIFICATION_CHECKBOX_DESCRIPTION}
        />
      )}
      <Signature
        signature={disclosureDocument?.inspectorSignature.replace('\n', '') || ''}
        signatureTitle="INSPECTOR"
        signatureDate={disclosureDocument?.inspectorSignatureDate || ''}
      />
    </AcknowledgmentContainer>
  );
};

const AcknowledgmentContainer = styled.div`
  break-inside: avoid-page;
  @media print {
    height: 100vh;
  }
`;
