import React from 'react';

import {
  SectionHeader,
  VehiclePropertiesColumnContainer,
  TitleText,
  ValueText,
  VehiclePropertiesRowContainer,
  PropertiesListItem
} from 'ewt/styles';

import { SellerDescription, Unit, UnitDescription } from 'common/model/Unit';
import { Disclosure } from 'common/model/Disclosure/Disclosure';

import { formatDate } from 'common/helpers/formatDate';
import { commaNumber } from 'common/helpers/commaNumber';
import { getTitle } from 'ewt/helpers/getTitle';

interface Props {
  sellerDescription: SellerDescription;
  unit: Unit;
  disclosure: Disclosure;
}

export const Overview: React.FC<Props> = ({ sellerDescription, unit, disclosure }) => {
  const { updatedOn, odometer } = disclosure;
  const { vin } = unit;
  const { exteriorColor = undefined, interiorColor = undefined } = unit.description as UnitDescription;
  const exteriorColorDescription = exteriorColor?.description || '';
  const interiorColorDescription = interiorColor?.description || '';

  return (
    <>
      <SectionHeader>Overview</SectionHeader>
      <VehiclePropertiesColumnContainer data-testid="vehicle-properties-list">
        <TitleText>Vehicle</TitleText>
        <ValueText>{vin}</ValueText>
        <ValueText>{getTitle(sellerDescription)}</ValueText>
      </VehiclePropertiesColumnContainer>
      <VehiclePropertiesRowContainer>
        <PropertiesListItem>
          <TitleText>Exterior Color</TitleText>
          <ValueText>{exteriorColorDescription}</ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>Interior Color</TitleText>
          <ValueText>{interiorColorDescription}</ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>Odometer</TitleText>
          <ValueText>
            {odometer && commaNumber(odometer)}
            {'mi'}
          </ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>Inspection Date</TitleText>
          <ValueText>{updatedOn && formatDate(new Date(updatedOn))}</ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>Inspection Type</TitleText>
          <ValueText>Self</ValueText>
        </PropertiesListItem>
      </VehiclePropertiesRowContainer>
    </>
  );
};
