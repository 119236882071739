import React from 'react';
import styled from 'styled-components';

import { DefaultRobotoText } from '../helpers/globalStyles';

interface Props {
  isConsumerInspection?: boolean;
}

export const PretermLegalText = ({ isConsumerInspection }: Props) => {
  return (
    <LegalTextContainer data-testid="legal-text" isConsumerInspection={isConsumerInspection}>
      <div>
        *This customer may be eligible for the Customer Unused Mileage Loyalty Beneﬁt if they are a minimum of 10% under
        their contractual mileage and contract another vehicle with BMW Group Financial Services. Any applicable credits
        would be applied to the customer’s new BMW Group Financial Services contract upon inception. Please refer to the
        loyalty grid located on page 4 of this document.
      </div>
      <br />
      <div>
        ***The Disposition Fee is a charge included on the Lease Agreement which is used to defray the costs of
        preparing and selling the vehicle at the end of the lease (either scheduled or early termination) if the vehicle
        is returned. It is not due if the customer purchases the vehicle. As a courtesy to our loyal customers, the
        disposition fee will be waived if a customer or household member lease or finance a new BMW through BMW
        Financial Services within 12 months of the termination date, or if they have any active account with BMW
        Financial Services. The disposition fee amount may vary based on state guidelines.
      </div>
      <br />
      <div>
        The total charges listed on this form may be subject to change if a vehicle is determined to have sustained
        damages not listed on this form. Examples of such damage may include but are not limited to any substantial body
        damage that exceeds the parameters listed on this form, missing, misaligned or broken components, any major
        mechanical problem, improper repairs, hail damage or a badly damaged interior (multiple burns, rips or water
        damage). In this case, a third-party inspector must evaluate the vehicle.
      </div>
      <br />
      <div>
        The inspection form must reﬂect the condition of the vehicle at turn-in. If turn-in condition is signiﬁcantly
        different, a re-inspection is required. The signature of both the customer and inspector are required for
        validation; however, PLEASE NOTE that the inspection is not complete until inspector uploads the required images
        as required by BMW Financial Services.{' '}
        {!isConsumerInspection && (
          <b>
            By signing this form, the customer and inspector acknowledge that they have read and agree with the
            assessment of this vehicle as itemized and in accordance with the parameters defined on this form.
          </b>
        )}
      </div>
      {isConsumerInspection && (
        <>
          <br />
          <div>
            <b>
              By signing this form, the customer and inspector acknowledge that they have read and agree with the
              assessment of this vehicle as itemized and in accordance with the parameters defined on this form.
            </b>
          </div>
        </>
      )}
    </LegalTextContainer>
  );
};

const LegalTextContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  color: #666666;
  font-size: 19px;
  padding: ${props => (props.isConsumerInspection ? '24px 0' : '10px 8px')};
  ${props => (props.isConsumerInspection ? DefaultRobotoText : '')}
`;
