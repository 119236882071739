import {
  DisclosureResponse,
  DisclosureQuestion,
  DisclosureQuestionAnswer,
  DisclosureResponseAnswer
} from 'common/model/Disclosure/Disclosure';
import { VehicleSeries } from '../constants/VehicleSeries';
import { DamageSection, SectionAnswer } from '../model/DamageSection';
import { InspectionItem, VehicleDamage } from '../services/bmwInspectionService/types';

export const defaultDamageSections: DamageSection[] = [
  {
    title: 'Body Assessment',
    questions: [],
    totalCost: 0
  },
  {
    title: 'Parts Charges',
    questions: [],
    totalCost: 0
  },
  {
    title: 'Option Charges',
    questions: [],
    totalCost: 0
  }
];

// Reduce the existing disclosure responses into an array of Section objects for display
export const mapDamageSections = (
  responses: DisclosureResponse[],
  questionnaire: DisclosureQuestion[],
  manheimAccountNumber: string,
  bmwInspectionItems: InspectionItem[],
  newOrPreowned: string
) => {
  const damageSections = responses.reduce<DamageSection[]>((sectionsAcc, response) => {
    // Find the matching Question in the formConfig
    const questionConfig = questionnaire.find(q => q.guid === response.guid);
    if (!questionConfig || !questionConfig.subSection) return sectionsAcc;
    const section: DamageSection = sectionsAcc.find(section => section.title === questionConfig.subSection) || {
      title: questionConfig.subSection,
      questions: [],
      totalCost: 0
    };

    const getDamageInspection = (account: string, partId: string, damageId: string): VehicleDamage | undefined => {
      if (account === manheimAccountNumber && partId && damageId && !damageId.includes(VehicleSeries.ISeries)) {
        const inspection = bmwInspectionItems.find(inspection => inspection.partId === partId);
        if (inspection) {
          const vehicleDamage = inspection.vehicleDamages.find(damage => damage.damageId === damageId);
          return vehicleDamage;
        }
      } else {
        return undefined;
      }
    };

    const lookupDamageCost = (
      quantity: number,
      partCostAmount: number,
      partMaximumAmount: number,
      partUsedPercentage: number,
      isISeries: boolean
    ): number => {
      let cost = quantity * partCostAmount;
      if (partMaximumAmount < cost || isISeries) {
        cost = partMaximumAmount;
      }
      if (newOrPreowned !== 'NEW') {
        cost *= partUsedPercentage / 100;
      }
      return cost;
    };

    const findAnswerByDisclosureAnswerValue = (value: string): DisclosureQuestionAnswer | undefined => {
      const answer = questionConfig.answers.find(answerConfig => answerConfig.value === value);

      return answer;
    };

    const lookupQuestionCost = (answers: SectionAnswer[]): number => {
      const questionPartMaximumAmount = answers
        .map(answer => answer.damage.partMaximumAmount)
        .reduce((a, b) => Math.max(a, b));
      const costSummary = answers.reduce((acc: number, answer: SectionAnswer) => acc + answer.cost, 0);
      return costSummary < questionPartMaximumAmount ? costSummary : questionPartMaximumAmount;
    };

    // Extract answer/damage/quantity details for display
    const answers: SectionAnswer[] = response.answers.reduce(
      (answers: SectionAnswer[], disclosureAnswer: DisclosureResponseAnswer) => {
        const answer = findAnswerByDisclosureAnswerValue(disclosureAnswer.value);
        const tags = answer?.tags || [];
        const dsiLeaseProtection = !!answer?.metaData?.dsi?.dsiLeaseProtection;
        if (tags.length) {
          const [account, partId, damageId, seriesType] = tags[0].split(':');
          const isISeries = seriesType === VehicleSeries.ISeries;
          const damageInspection = getDamageInspection(account, partId, damageId);
          if (damageInspection) {
            answers.push({
              value: disclosureAnswer.value,
              notes: disclosureAnswer.notes,
              quantity: disclosureAnswer.quantity,
              leaseProtection: dsiLeaseProtection,
              damage: damageInspection,
              cost: lookupDamageCost(
                disclosureAnswer.quantity || 1,
                damageInspection.partCostAmount,
                damageInspection.partMaximumAmount,
                damageInspection.partUsedPercentage,
                isISeries
              )
            });
          }
        }
        return answers;
      },
      []
    );

    const sectionIndex = sectionsAcc.findIndex(s => s === section);
    if (answers.length && sectionIndex >= 0) {
      sectionsAcc[sectionIndex].questions.push({
        questionText: questionConfig.text,
        answers,
        cost: lookupQuestionCost(answers)
      });
      sectionsAcc[sectionIndex].totalCost = sectionsAcc[sectionIndex].questions
        .flat()
        .map(q => q.cost)
        .reduce((acc, curr) => acc + curr);
    }
    return sectionsAcc;
  }, defaultDamageSections);

  return damageSections;
};
