import React from 'react';
import styled from 'styled-components';

import { PoppinsHeader, DefaultRobotoText, TopLine } from '../../common/helpers/globalStyles';
import { formatCurrency } from 'common/helpers/formatCurrency';

interface Props {
  grandTotal: number;
}

export const GrandTotal: React.FC<Props> = ({ grandTotal }) => (
  <GrandTotalContainer>
    <GrandTotalContent>
      <GrandTotalTitle>{'Pre-Term Inspection \n Grand Total'}</GrandTotalTitle>
      <GrandTotalValue>{formatCurrency(grandTotal)}</GrandTotalValue>
    </GrandTotalContent>
  </GrandTotalContainer>
);
const GrandTotalContainer = styled.div`
  background-color: #e1f4ff;
  page-break-inside: avoid;
  ${TopLine}
`;

const GrandTotalContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  margin: 0 auto;

  @media print, screen and (max-width: 1200px) {
    max-width: 80%;
  }

  @media print, screen and (max-width: 960px) {
    max-width: 680px;
  }
`;

const GrandTotalTitle = styled.div`
  ${PoppinsHeader}
`;

const GrandTotalValue = styled.div`
  ${DefaultRobotoText}
  font-size: 22px;
  font-weight: 700;
`;
