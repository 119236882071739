import { ExpandedDisclosure } from 'common/model/Disclosure/ExpandedDisclosure';
import { ChargeType, DamageType, InspectionChargesDetailsType } from 'ewt/requests/chargesAPI/types';

const insertDamageItem = (questionGuid: string, location: string, damages: ChargeType[], damageItem: DamageType) => {
  const existingRecord = damages.find(item => item.questionGuid === questionGuid);

  if (existingRecord) {
    existingRecord.damages.push(damageItem);
  } else {
    damages.push({
      damages: [damageItem],
      location,
      questionGuid
    });
  }
};

export const getFinalCharges = (
  expandedDisclosure: ExpandedDisclosure,
  chargesDetails: InspectionChargesDetailsType
) => {
  if (!expandedDisclosure.alternateResponses) {
    return {
      addedDamages: [],
      revisedDamages: chargesDetails.charges
    };
  }

  const addedDamages: ChargeType[] = [];
  const revisedDamages: ChargeType[] = [];

  chargesDetails.charges.forEach(charge => {
    const questionGuid = charge.questionGuid;
    const questionAnswers = expandedDisclosure.alternateResponses?.find(response => response.guid === questionGuid);

    charge.damages.forEach(damage => {
      if (!questionAnswers) {
        insertDamageItem(questionGuid, charge.location, revisedDamages, damage);
        return;
      }

      const answerMetadata = questionAnswers.answers.find(answer => answer.value === damage.type);

      if (!answerMetadata) return;

      const chargeQuantity = damage.quantity || 1;
      if (answerMetadata.metaData?.added?.imageLinks.length) {
        /* answerMetadata.metaData.added.quantity is a tricky
           see details in hitl repo
           used imageLinks.length instead */
        const addedDamageQuantity = answerMetadata.metaData.added.imageLinks.length;
        const addedDamageItem = {
          price: (damage.price * addedDamageQuantity) / chargeQuantity,
          type: damage.type,
          chargeable: damage.chargeable,
          imageLinks: answerMetadata.metaData.added.imageLinks,
          quantity: addedDamageQuantity
        };

        insertDamageItem(questionGuid, charge.location, addedDamages, addedDamageItem);

        const dQuantity = chargeQuantity - addedDamageQuantity;

        if (dQuantity) {
          const damageItem = {
            price: (damage.price * dQuantity) / chargeQuantity,
            chargeable: damage.chargeable,
            quantity: dQuantity,
            type: damage.type
          };

          insertDamageItem(questionGuid, charge.location, revisedDamages, damageItem);
        }
      } else {
        const damageItem = {
          price: damage.price,
          chargeable: damage.chargeable,
          quantity: chargeQuantity,
          type: damage.type
        };

        insertDamageItem(questionGuid, charge.location, revisedDamages, damageItem);
      }
    });
  });

  return { addedDamages, revisedDamages };
};
