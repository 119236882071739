import { DamageSection } from '../model/DamageSection';

export const calculateLeaseProtectionBenefitAmount = (damageSections: DamageSection[]): number =>
  damageSections
    .flatMap(section => section.questions)
    .map(question => {
      const leaseProtectionAmount = question.answers.reduce(
        (acc, answer) => (answer.leaseProtection ? acc + answer.cost : acc),
        0
      );
      return leaseProtectionAmount > question.cost ? question.cost : leaseProtectionAmount;
    })
    .reduce((acc, amount) => acc + amount, 0);
