import React from 'react';
import styled from 'styled-components';

interface Props {
  vin: string;
  isProtected: boolean;
  fontSize?: number;
  isConsumerInspection?: boolean;
}

export const VinNumber = ({ vin, isProtected, fontSize, isConsumerInspection }: Props) => {
  const formatedVin = isProtected ? vin.replace(vin.substring(0, 9), 'XXXXXXXXX') : vin;

  const { VinContainer, ShortVinContainer } = isConsumerInspection
    ? { VinContainer: ConsumerStyleVin, ShortVinContainer: ConsumerStyleShortVin }
    : { VinContainer: Vin, ShortVinContainer: ShortVin };

  return (
    <div>
      {formatedVin.length === 17 ? (
        <>
          <VinContainer style={fontSize ? { fontSize } : {}} data-testid="vin">
            {formatedVin.substring(0, 9)}
          </VinContainer>
          <ShortVinContainer style={fontSize ? { fontSize } : {}} data-testid="vin-bold">
            {formatedVin.substring(9)}
          </ShortVinContainer>
        </>
      ) : (
        <Vin data-testid="vin">{formatedVin}</Vin>
      )}
    </div>
  );
};

const ShortVin = styled.span`
  color: black;
  font-size: 19px;
  font-family: BMWGroupCondensed-Bold;
`;

const ConsumerStyleShortVin = styled.span`
  color: black;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
`;

const Vin = styled.span`
  color: black;
  font-size: 19px;
`;

const ConsumerStyleVin = styled.span`
  color: black;
  font-size: 16px;
  font-family: Roboto;
`;
