import { History, LocationState, LocationDescriptor, LocationDescriptorObject } from 'history';
import queryString from 'query-string';

type CreateHistory<TOptions, TState> = (options?: TOptions) => History<TState>;

function preserveQueryParameters(history: History, location: LocationDescriptorObject<any>): LocationDescriptorObject {
  const currentQuery = queryString.parse(history.location.search);
  if (location.search) {
    Object.assign(currentQuery, queryString.parse(location.search));
  }
  location.search = queryString.stringify(currentQuery);
  return location;
}

function createLocationDescriptorObject(location: LocationDescriptor, state?: LocationState): LocationDescriptorObject {
  return typeof location === 'string' ? { pathname: location, state } : location;
}

export function createPreserveQueryHistory<TOptions, TState>(
  createHistory: CreateHistory<TOptions, TState>
): CreateHistory<TOptions, TState> {
  return (options?: TOptions) => {
    const history = createHistory(options);
    const oldPush = history.push,
      oldReplace = history.replace;
    history.push = (path: LocationDescriptor, state?: TState) =>
      oldPush.apply(history, [(preserveQueryParameters as any)(history, createLocationDescriptorObject(path, state))]);
    history.replace = (path: LocationDescriptor, state?: LocationState) =>
      oldReplace.apply(history, [
        (preserveQueryParameters as any)(history, createLocationDescriptorObject(path, state))
      ]);
    return history;
  };
}
