import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import App from './App';
import '@prism/styles/prism.comps.min.css';
import './common/styles/index.scss';
import { logger } from 'common/logger/logger';

Modal.setAppElement('#root');
ReactDOM.render(<App />, document.getElementById('root'));
logger.info('app:initialized');
