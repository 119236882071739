import axios from 'axios';
import { Environment } from 'common/model/Environment';
import { parse as parseXML } from 'fast-xml-parser';

import { getEnvironment } from '../../../../../common/api/setup';
import { BMW_INSPECTION_SERVICE_BASE_URLS } from './bmwInspectionServiceBaseUrls';
import { BMWInspectionServiceDataApiType } from './types';

type RequestParams = {
  bodyXML: string;
  SOAPAction: string;
};

const environment = getEnvironment() as Environment;

export const fetchBMWInspectionServiceData = async (
  requestParams: RequestParams
): Promise<BMWInspectionServiceDataApiType> => {
  const { bodyXML, SOAPAction } = requestParams;
  if (!bodyXML || !SOAPAction) {
    throw new Error('Invalid bodyXML or SOAPAction');
  }

  const url = `${BMW_INSPECTION_SERVICE_BASE_URLS[environment]}/inspection`;
  const config = {
    headers: { 'Content-Type': 'application/xml', SOAPAction },
    ignoreDebugHeaders: true
  };
  const response = await axios.post(url, bodyXML, config);
  const jsonResult = parseXML(response.data, { ignoreNameSpace: true });

  return jsonResult;
};
