import React from 'react';
import Checkbox from '@prism/checkbox';
import styled from 'styled-components';

interface Props {
  checked: boolean;
  description: string;
  agreementDescription?: string;
  isConsumerInspection?: boolean;
}

const consumerCheckboxStyles = { backgroundColor: '#005484', border: '3px solid #005484' };

export const CheckboxWithDescription: React.FC<Props> = ({
  checked,
  description,
  isConsumerInspection,
  agreementDescription
}) => (
  <AcknowledgeContainer>
    <CheckboxWrapper isConsumerInspection={isConsumerInspection}>
      {isConsumerInspection ? (
        <Checkbox
          style={{
            width: 25,
            height: 25,
            margin: 0,
            ...consumerCheckboxStyles
          }}
          checked={checked}
          disabled
        />
      ) : (
        <Checkbox style={{ width: 30, height: 30, margin: 0 }} checked={checked} disabled />
      )}
    </CheckboxWrapper>
    <AcknowledgeTextContainer isConsumerInspection={isConsumerInspection}>
      {isConsumerInspection && agreementDescription ? (
        <ConsumerAcknowledgeText>{agreementDescription}</ConsumerAcknowledgeText>
      ) : (
        <AcknowledgeText>{description}</AcknowledgeText>
      )}
    </AcknowledgeTextContainer>
  </AcknowledgeContainer>
);

const AcknowledgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0px 30px 15px;
  break-inside: avoid-page;
`;

const CheckboxWrapper = styled.div<{
  isConsumerInspection?: boolean;
}>`
  flex: ${props => (props.isConsumerInspection ? '0.05' : '0.1')};
  border-radius: 0;
  align-items: center;
`;

const AcknowledgeTextContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  flex: 0.95;
  flex: ${props => (props.isConsumerInspection ? '0.95' : '0.9')};
  border-radius: 0;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

const AcknowledgeText = styled.p`
  font-family: BMWGroupCondensed-Bold;
  font-size: 19px;
  margin: 0;
`;

const ConsumerAcknowledgeText = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;
