export const ACKNOWLEDGEMENT_CHECKBOX_DESCRIPTION =
  'I acknowledge that this Dealer Self-Inspection is conducted as a courtesy to identify, and provide me with the opportunity to repair excess wear and tear to the vehicle. I understand that the vehicle may be subjected to additional inspections, and that the total charges identified during this Dealer Self-Inspection are subject to change if the vehicle is determined to have sustained damages not identified during this inspection, regardless of whether such damage could have been identified during this inspection. Examples of such additional charges include, but are not limited to: aftermarket alterations to the vehicle, including, but not limited to, exhaust, suspension, and modifications to any of the vehicle’s computer systems; improper repairs, including, but not limited to, repairs completed without manufacturer-approved parts; any substantial body damage that exceeds the parameters of this inspection; any major mechanical problem; missing, misaligned, or broken components; hail damage; and damage to the vehicle’s interior (multiple burns, rips, or water damage).';

export const CUSTOMER_CERTIFICATION_CHECKBOX_DESCRIPTION =
  'I hereby certify that I have not altered the functionality of any of the vehicle’s computer systems, whether to alter the performance of the vehicle or otherwise. I understand that the Company may run diagnostic tests on the vehicle to confirm that the onboard computer systems are working to factory specifications, and that I may be in default of my obligations under the Lease if any unauthorized modifications are identified.';

export const PRETERM_ACKNOWLEDGEMENT_CHECKBOX_DESCRIPTION =
  'I acknowledge that this Pre-Term Dealer Self Inspection is conducted as a courtesy to identify, and provide me with the opportunity to repair excess wear and tear to the vehicle. I understand that the vehicle may be subjected to additional inspections, and that the total charges identified during this Dealer Self-Inspection are subject to change if the vehicle is determined to have sustained damages not identified during this inspection, regardless of whether such damage could have been identified during this inspection. Examples of such additional charges include, but are not limited to: aftermarket alterations to the vehicle, including, but not limited to, exhaust, suspension, and modifications to any of the vehicle’s computer systems; improper repairs, including, but not limited to, repairs completed without manufacturer-approved parts; any substantial body damage that exceeds the parameters of this inspection; any major mechanical problem; missing, misaligned, or broken components; hail damage; and damage to the vehicle’s interior (multiple burns, rips, or water damage).';

export const PRETERM_CUSTOMER_CERTIFICATION_CHECKBOX_DESCRIPTION =
  'I hereby certify that I have not altered the functionality of any of the vehicle’s computer systems, whether to alter the performance of the vehicle or otherwise. I understand that the Company may run diagnostic tests on the vehicle to confirm that the onboard computer systems are working to factory specifications, and that I may be in default of my obligations under the Lease if any unauthorized modifications are identified.';

export const INSPECTOR_CERTIFICATION_CHECKBOX_DESCRIPTION =
  'I certify that my inspection of this vehicle included confirming that the customer has removed all customer personal information from the vehicle’s iDrive system. If customer did not properly remove such personal information, my inspection included the removal of such information from the iDrive system prior to completing this Dealer Self-Inspection form.';

export const ACKNOWLEDGEMENT_CHECKBOX_AGREEMENT = 'Yes, I Acknowledge the Following:';

export const CUSTOMER_CERTIFICATION_CHECKBOX_AGREEMENT = 'Yes, I Hereby Certify the Following:';
