import { Logger } from 'kersplunk';
import { v4 as uuidv4 } from 'uuid';
import { getDebugMetadata } from './debugMetadata';

// Session ID created once per app start
export const SESSION_ID = uuidv4();

export const logger = Logger.singleton(
  {
    splunkUrl: 'https://http-inputs-coxauto.splunkcloud.com/services/collector/event',
    authToken: 'B58D48D7-FCBC-5B29-8DE8-BCB9554A9F80',
    splunkMeta: {
      index: 'man_app',
      source: 'next-inspect-plus-web-views',
      sourcetype: '_json'
    },
    enabled: process.env.NODE_ENV !== 'test',
    interceptor: (log: object | undefined) => {
      return {
        ...log,
        cai_app: 'next-inspect-plus-web-views', // eslint-disable-line @typescript-eslint/camelcase, camelcase
        cai_environment: process.env.REACT_APP_CAI_ENV, // eslint-disable-line @typescript-eslint/camelcase, camelcase
        meta: {
          ...getDebugMetadata(),
          nodeEnv: process.env.NODE_ENV
        }
      };
    }
  },
  'debug',
  'error',
  'info',
  'warn',
  'track'
);
