import { css } from 'styled-components';

export const TopLine = css`
  border-top: 1px solid #babcbe;
`;

export const PoppinsHeader = css`
  color: black;
  font-size: 20px;
  line-height: 24px;
  font-family: Poppins;
`;

export const BMWHeader = css`
  color: #444444;
  font-size: 21px;
  font-family: BMWGroupCondensed-Bold;
`;

export const DefaultRobotoText = css`
  color: black;
  font-size: 16px;
  font-family: 'Roboto';
`;
