import React from 'react';

import { NewHampshireStateDisclosurePage } from './state-disclosures/NewHampshireStateDisclosurePage';
import { WisconsinStateDisclosurePage } from './state-disclosures/WisconsinStateDisclosurePage';

const StateDisclosureByState: {
  [stateCode: string]: React.FC<{}> | undefined;
} = {
  NH: NewHampshireStateDisclosurePage,
  WI: WisconsinStateDisclosurePage
};

export const StateDisclosure = ({ stateCode }: { stateCode: string }) => {
  const StateDisclosureComponent = StateDisclosureByState[stateCode] ?? (() => null);

  return <StateDisclosureComponent />;
};
