import React from 'react';
import { Text } from './stateDisclosuresStyle';

export const StateDisclosureAddress = () => (
  <>
    <Text center>U.S. Bank</Text>
    <Text center>6400 Main St</Text>
    <Text center>Amherst, NY 14221</Text>
  </>
);
