import React from 'react';
import styled from 'styled-components';

import verifiedStatus from '../assets/images/verified-status.svg';
import unverifiedStatus from '../assets/images/unverified-status.svg';

export const VerificationStatus = ({ verified }: { verified: boolean }) => (
  <VerificationStatusContainer>
    <img alt={verified ? 'verified' : 'unverified'} src={verified ? verifiedStatus : unverifiedStatus} />
  </VerificationStatusContainer>
);

const VerificationStatusContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
