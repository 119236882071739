export enum DisclosureDocumentType {
  LeaseEnd = 'lease_end_inspection_form',
  Odometer = 'odometer_statement',
  LeaseEndPdf = 'lease_end_pdf',
  pretermPdf = 'preterm_pdf'
}

export type DisclosureDocument = {
  href?: string;
  disclosure?: {
    href: string;
  };
  pdf?: {
    href: string;
  };
  type?:
    | DisclosureDocumentType.LeaseEnd
    | DisclosureDocumentType.Odometer
    | DisclosureDocumentType.LeaseEndPdf
    | DisclosureDocumentType.pretermPdf;
  stateDisclosureAcknowledged?: boolean | undefined;
  stateDisclosureAcknowledgedDate?: string;
};

export type DisclosureDocuments = {
  items: DisclosureDocument[];
};
