export enum DisclosureDocumentType {
  LeaseEndInspectionForm = 'lease_end_inspection_form',
  OdometerStatement = 'odometer_statement',
  CustomerPretermInspectionForm = 'customer_preterm_inspection_form'
}

export type DisclosureDocument = {
  href: string;
  type: DisclosureDocumentType;
  clientSignature: string;
  clientSignatureDate: string;
  inspectorSignature: string;
  inspectorSignatureDate: string;
  disclosure: {
    href: string;
  };
  odoExcess: boolean;
  odoNotMileage: boolean;
  dmeAcknowledgement: boolean;
  alterationsAddlCharges: boolean;
  personalData: boolean;
  createdOn: string;
  updatedOn: string;
};
