import React from 'react';
import styled from 'styled-components';

export const RibbonIndicator = ({ text }: { text: string }) => <Content>{text}</Content>;

const Content = styled.div`
  display: inline-block;
  position: relative;
  background: #c1dff2;
  font-size: 12px;
  font-family: BMWGroupCondensed-Bold;
  font-weight: 900;
  color: black;
  height: 16px;
  padding: 1px 8px 1px 8px;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    right: -8px;
    border-width: 4px;
    border-style: solid;
    border-color: #c1dff2 transparent transparent #c1dff2;
    top: 0;
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    right: -8px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent #c1dff2 #c1dff2;
    bottom: 0;
  }
`;
