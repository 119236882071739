import axios from 'axios';

import { DamageSection } from 'bmw/common/model/DamageSection';

export type InspectionDetails = {
  currentMileage: number | undefined;
  totalMileageCharge: number;
  leaseProtectionBenefitAmount: number;
  damages: DamageSection[];
};

export const getBmwInspectionDetails = async (
  vin: string,
  disclosureId: string,
  usageId: string
): Promise<InspectionDetails> => {
  const response = await axios.request<InspectionDetails>({
    method: 'get',
    url: '/getBmwInspectionDetails',
    params: { vin, disclosureId, usageId }
  });

  return response.data;
};
