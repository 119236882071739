import { customHeaderPrefix } from 'common/constants/customHeaderPrefix';
import { kebabCase } from 'lodash-es';
import { SESSION_ID } from './logger';

const debugMetadata: Record<string, string> = {
  sessionId: SESSION_ID
};

export const setDebugMetadataItem = (key: string, value: string) => {
  debugMetadata[key] = value;
};

export const setVinMetadata = (vin: string) => {
  setDebugMetadataItem('vin', vin);
};

export const setDisclosureIdMetadata = (disclosureId: string) => {
  setDebugMetadataItem('disclosureId', disclosureId);
};

export const getDebugMetadata = () => debugMetadata;

export const getDebugMetadataAsHeaders = () =>
  Object.entries(debugMetadata).reduce(
    (headers, [key, value]) => ({
      ...headers,
      [`${customHeaderPrefix}${kebabCase(key)}`]: value
    }),
    {}
  );
