import React from 'react';
import { StateDisclosureAddress } from './StateDisclosureAddress';
import { Header, PageContainer, Text } from './stateDisclosuresStyle';

export const WisconsinStateDisclosurePage: React.FC = () => {
  return (
    <PageContainer>
      <Header>Wisconsin Residents Only</Header>
      <Text center>PRE-TERMINATION NOTICE OF EXCESS WEAR AND DAMAGE</Text>
      <Text paddingTop={40} paddingBottom={60}>
        This is not an invoice. Estimated charges are based upon damage described at the time of the inspection.
        Additional damage, or misrepresented damage will be captured upon lease turn-in.
      </Text>
      <StateDisclosureAddress />
      <Text paddingTop={100} paddingBottom={20}>
        If this inspection report was prepared prior to the termination date of the consumer lease, you may avoid any
        excess wear and damage charges by having such items satisfactorily repaired prior to the return of the vehicle.
        The holder may inspect the vehicle at or after its return and may seek additional charges for excess wear and
        damage only by written notice to you, and only for excess wear and damage incurred after the date of that
        inspection. Any estimated charges for excess wear and damage under this inspection or your own inspection shall
        be due when the lease terminates.
      </Text>
    </PageContainer>
  );
};
