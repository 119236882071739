export const formatDate = (date: Date | string, separator = '/'): string => {
  if (date instanceof Date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${mm}${separator}${dd}${separator}${yyyy}`;
  }
  const [yyyy, mm, dd] = date.split('-');
  return `${mm}${separator}${dd}${separator}${yyyy}`;
};
