import axios, { Method } from 'axios';

import { VehicleInformation } from '../model/VehicleInformation';

type ParamsType = {
  chromeData?: boolean;
};

export async function fetchBuildDataByVin(vin: string, params?: ParamsType) {
  const config = {
    method: 'get' as Method,
    url: `/descriptions/build/id/vin/${vin}`,
    ...(params && { params })
  };

  const response = await axios.request<VehicleInformation>(config);
  return response.data;
}
