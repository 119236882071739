import { Usage } from 'common/model/Usage';
import { DamageSection } from '../model/DamageSection';

interface OptionsPayload {
  damageSections: DamageSection[];
  leaseProtectionBenefitAmount: number;
  totalMileageCharge: number;
  usage?: Usage;
}

export const getTotalCostOptions = (options: OptionsPayload) => {
  const { damageSections, usage, totalMileageCharge, leaseProtectionBenefitAmount } = options;
  const bodyAssessmentTotal = damageSections[0]?.totalCost;
  const partsChargesTotal = damageSections[1]?.totalCost;
  const optionChargesTotal = damageSections[2]?.totalCost;
  const dispositionFeeAmount = usage?.dispositionFeeAmount || 0;

  const grandTotal =
    bodyAssessmentTotal +
    partsChargesTotal +
    optionChargesTotal +
    totalMileageCharge +
    dispositionFeeAmount -
    leaseProtectionBenefitAmount;

  return {
    bodyAssessmentTotal,
    partsChargesTotal,
    optionChargesTotal,
    dispositionFeeAmount,
    grandTotal
  };
};
