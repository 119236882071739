import axios from 'axios';

import { Collection } from 'common/model/Collection';
import { DisclosureDocument } from 'bmw/common/model/DisclosureDocument';

export async function fetchDisclosureDocumentsByDisclosureId(disclosureId: string): Promise<DisclosureDocument[] | []> {
  const response = await axios.request<Collection<DisclosureDocument>>({
    method: 'get',
    url: `/disclosureDocuments/disclosure/${disclosureId}`
  });

  return response.data.items;
}
