import React from 'react';
import styled from 'styled-components';

import selfInspectionLogo from '../assets/images/logo.svg';

export const Header: React.FC<{ documentName: string }> = ({ documentName }) => {
  return (
    <div>
      <LogoContainer>
        <SelfInspectionLogo src={selfInspectionLogo} />
      </LogoContainer>
      <TextHeaderContainer>
        <DocumentName>{documentName}</DocumentName>
      </TextHeaderContainer>
    </div>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
`;

const SelfInspectionLogo = styled.img`
  width: 20%;
`;

const TextHeaderContainer = styled.div`
  margin-bottom: 5px;
`;

const DocumentName = styled.div`
  color: black;
  font-family: Roboto;
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
`;
