import React from 'react';
import { StateDisclosureAddress } from './StateDisclosureAddress';
import { Header, PageContainer, Text } from './stateDisclosuresStyle';

export const NewHampshireStateDisclosurePage: React.FC = () => {
  return (
    <PageContainer>
      <Header>New Hampshire Residents Only</Header>
      <Text center>PRE-TERMINATION NOTICE OF EXCESS WEAR AND DAMAGE</Text>
      <Text paddingTop={40} paddingBottom={60}>
        You are being asked to pay this amount for excess wear and damage. If you do not agree with this amount and top
        reserve valuable rights, you must notify U.S. Bank within seven (7) days of receipt of this bill of your
        disagreement and obtain and deliver to U.S. Bank, within thirty (30) days after delivery of this bill, an
        itemized inspection report and estimate of the cost of repairing such excess wear and damage from an appraiser
        agreed to by U.S. Bank, and payment of any charges due under the inspection you obtained. If you properly obtain
        and deliver such appraisal and tender any amounts due, such appraisal shall be binding on U.S. Bank. If you fail
        to do so, U.S. Bank inspection shall be conclusive. If you choose to have an independent appraisal, this will be
        performed at your own expense.
      </Text>
      <StateDisclosureAddress />
      <Text paddingTop={100} paddingBottom={20}>
        If this inspection report was prepared by U.S. Bank prior to the scheduled termination, you may avoid excess
        wear and damage charges by having such items satisfactorily repaired prior to the return of the vehicle. U.S.
        Bank may inspect the vehicle at or after its return and may seek additional charges only by written notice and
        only for wear and damage incurred after the date of U.S. Bank vehicle inspection. In addition, any charges for
        wear and damage under this inspection or your own inspection shall be due when the lease terminates.
      </Text>
    </PageContainer>
  );
};
