import { fetchBMWInspectionServiceData } from './fetchBMWInspectionServiceData';
import { InspectionItemEntityApiType } from './types';

const PRE_TERM_INSPECTION_TYPE_COUNTRY_ID = 12;
const END_TERM_INSPECTION_TYPE_COUNTRY_ID = 10;
const INSPECTION_PARTY_ROLE_ID = 59404;
const INSPECTION_COUNTRY_CODE = 'USA';

const getBodyXml = (vin: string, isPreTerm: boolean): string => {
  const inspectionTypeCountryId = isPreTerm ? PRE_TERM_INSPECTION_TYPE_COUNTRY_ID : END_TERM_INSPECTION_TYPE_COUNTRY_ID;

  const BODY_XML = `
  <Envelope xmlns='http://schemas.xmlsoap.org/soap/envelope/'>
    <Header/>
    <Body>
      <PrepareInspectionWithVINPublicRequestMessage xmlns="http://services.bmwfs.com/Services/Public/EndOfTerm/Inspection/V200906">
        <Request xmlns:b="http://schemas.bmwfs.com/Services/Public/EndOfTerm/Inspection/V200906" xmlns:i="http://www.w3.org/2001/XMLSchema-instance">
          <b:InspectionTypeCountryId type="xs:int">${inspectionTypeCountryId}</b:InspectionTypeCountryId>
          <b:VIN type="xs:string">${vin}</b:VIN>
          <b:InspectingPartyRoleId type="xs:int">${INSPECTION_PARTY_ROLE_ID}</b:InspectingPartyRoleId>   
          <b:InspectionCountryCode type="xs:string">${INSPECTION_COUNTRY_CODE}</b:InspectionCountryCode>
        </Request>
      </PrepareInspectionWithVINPublicRequestMessage>
    </Body>
    </Envelope>
  `;

  return BODY_XML;
};

const SOAP_ACTION =
  'http://services.bmwfs.com/Services/Public/EndOfTerm/Inspection/V200906/IInspectionService/PrepareInspectionWithVIN';

export const fetchInspectionByVin = async (vin: string, isPreTerm: boolean): Promise<InspectionItemEntityApiType[]> => {
  const bodyXML = getBodyXml(vin, isPreTerm);
  const response = await fetchBMWInspectionServiceData({
    bodyXML,
    SOAPAction: SOAP_ACTION
  });
  const inspectionItems =
    response.Envelope.Body.PrepareInspectionWithVINPublicResponseMessage.Response.InspectionItem.InspectionItemEntity;

  return inspectionItems;
};
