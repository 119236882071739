import { fetchInspectionByVin } from 'bmw/common/api/requests/bmwInspectionServiceApi/fetchInspectionByVin';
import {
  InspectionItemEntityApiType,
  VehicleDamageEntityApiType
} from 'bmw/common/api/requests/bmwInspectionServiceApi/types';
import { InspectionItem, VehicleDamage } from './types';

const mapVehicleDamageEntity = (vehicleDamageEntity: VehicleDamageEntityApiType): VehicleDamage => {
  const vehicleDamage = {
    damageId: vehicleDamageEntity.Key.DamageId.toString(),
    partCostAmount: vehicleDamageEntity.Mutable.PartCostAmount,
    partMaximumAmount: vehicleDamageEntity.Mutable.PartMaximumAmount,
    partUsedPercentage: vehicleDamageEntity.Mutable.PartUsedPercentage
  };

  return vehicleDamage;
};

const mapInspectionItems = (apiItems: InspectionItemEntityApiType[]): InspectionItem[] => {
  const inspectionItems = apiItems.map((item: InspectionItemEntityApiType) => {
    const partId = item.Key.InspectionItemId.toString();
    const partDescription = item.PartDescription;
    const vehicleDamageEntity = item.VehicleDamage.VehicleDamageEntity;
    const vehicleDamages: VehicleDamage[] = Array.isArray(vehicleDamageEntity)
      ? vehicleDamageEntity.map(mapVehicleDamageEntity)
      : [mapVehicleDamageEntity(vehicleDamageEntity)];

    return {
      partId,
      partDescription,
      vehicleDamages
    };
  });

  return inspectionItems;
};

export const getBmwInspectionItems = async (vin: string, isPreTerm: boolean): Promise<InspectionItem[]> => {
  const inspectionItemsApi: InspectionItemEntityApiType[] = await fetchInspectionByVin(vin, isPreTerm);
  const inspectionItems: InspectionItem[] = mapInspectionItems(inspectionItemsApi);

  return inspectionItems;
};
