import axios from 'axios';
import { Disclosure } from 'common/model/Disclosure/Disclosure';

export async function fetchDisclosureById(disclosureId: string) {
  const response = await axios.request<Disclosure>({
    method: 'get',
    url: `/disclosures/id/${disclosureId}`,
    params: {
      expand: 'questionnaire'
    }
  });

  return response.data;
}
