import React from 'react';
import styled from 'styled-components';

import bmwGroupLogo from 'bmw/common/assets/images/bmw-group-fs-logo.jpg';
import bmwFranchisesLogo from 'bmw/common/assets/images/bmw-franchises-logo.jpg';

export const PDFHeader: React.FC = () => {
  return (
    <div>
      <LogoContainer>
        <BmwGroupLogo src={bmwGroupLogo} />
        <BmwFranchisesLogo src={bmwFranchisesLogo} />
      </LogoContainer>
    </div>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
`;

const BmwGroupLogo = styled.img`
  width: 16%;
`;
const BmwFranchisesLogo = styled.img`
  width: 25%;
`;
