import React from 'react';
import styled from 'styled-components';

import { formatDate } from 'common/helpers/formatDate';

interface Props {
  signature: string;
  signatureDate: string;
}

export const Signature: React.FC<Props> = ({ signature, signatureDate }) => {
  return (
    <SignatureContainer>
      <SignatureContentContainer>
        <ImagePreviewContainer>
          {signature && <ImagePreview src={`data:image/png;base64,${signature}`} />}
        </ImagePreviewContainer>
        <DateContainer>
          <DateWrapper>{formatDate(new Date(signatureDate))}</DateWrapper>
        </DateContainer>
      </SignatureContentContainer>
    </SignatureContainer>
  );
};

const SignatureContainer = styled.div`
  margin: 15px;
  break-inside: avoid-page;
`;

const SignatureContentContainer = styled.div`
  display: flex
  flex-direction: row;
  justify-content: space-between;
  background: white;
  height: auto;
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex: 0.75;
  justify-content: center;
  align-items: center;
  margin: 5px;
`;

const ImagePreview = styled.img`
  height: 200px;
`;

const DateContainer = styled.div`
  flex: 0.2;
  align-items: flex-start;
  text-align: right;
  padding: 8px 15px;
`;

const DateWrapper = styled.p`
  color: #666666;
  font-size: 16px;
  font-weight: 400;
`;
