import axios from 'axios';

import { Collection } from 'common/model/Collection';
import { InspectionChargesDetailsType } from './types';

export type InspectionChargesResponseType = {
  disclosureId: string;
  items: InspectionChargesDetailsType[];
};

export async function getInspectionChargesDetails(disclosureId: string): Promise<InspectionChargesDetailsType[]> {
  const { data: response } = await axios.request<Collection<InspectionChargesResponseType>>({
    method: 'get',
    url: '/csi/charges',
    params: {
      disclosureIds: disclosureId
    }
  });

  if (!response.items.length) {
    throw new Error(`Inspection charges details were not found for disclosureId=${disclosureId}`);
  }

  return response.items[0].items;
}
