import React from 'react';
import styled from 'styled-components';

import bmwGroupLogo from 'bmw/common/assets/images/bmw-group-fs-logo.jpg';
import bmwFranchisesLogo from 'bmw/common/assets/images/bmw-franchises-logo.jpg';

interface Props {
  dealershipName: string;
  isPreterm?: boolean;
}

export const PDFHeader: React.FC<Props> = ({ dealershipName, isPreterm }) => {
  const documentName = isPreterm ? 'Dealer Pre-Term Inspection Form' : 'Final End of Term Inspection';

  return (
    <div>
      <LogoContainer>
        <BmwGroupLogo src={bmwGroupLogo} />
        <BmwFranchisesLogo src={bmwFranchisesLogo} />
      </LogoContainer>
      <TextHeaderContainer>
        <DocumentName>{documentName}</DocumentName>
        <DealershipName>Inspecting Dealer: {dealershipName}</DealershipName>
      </TextHeaderContainer>
    </div>
  );
};

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 45px;
`;

const BmwGroupLogo = styled.img`
  width: 16%;
`;
const BmwFranchisesLogo = styled.img`
  width: 25%;
`;

const TextHeaderContainer = styled.div`
  margin-bottom: 5px;
`;

const DocumentName = styled.div`
  font-size: 26px;
  font-family: BMWGroupCondensed-Bold;
`;

const DealershipName = styled.div`
  font-size: 26px;
`;
