import axios from 'axios';
import { ExpandedDisclosure } from 'common/model/Disclosure/ExpandedDisclosure';

export async function fetchExpandedDisclosureById(disclosureId: string) {
  const response = await axios.request<ExpandedDisclosure>({
    method: 'get',
    url: `/disclosures/id/${disclosureId}`,
    params: {
      expand: 'questionnaire,unit,unit.sellerDescriptions,disclosureDocuments',
      zipQuestionnaire: true
    }
  });

  return response.data;
}
