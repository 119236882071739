import styled from 'styled-components';

export const PageContainer = styled.div`
  break-before: page;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;

export const Text = styled.p<{
  center?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
}>`
  font-size: 20px;
  line-height: 25px;
  color: black;
  font-family: BMWGroupCondensed-Regular;
  ${props => (props.center ? 'text-align: center;' : '')}
  ${props => (props.paddingTop ? `padding-top: ${props.paddingTop}px;` : '')}
  ${props => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : '')}
`;

export const Header = styled.div`
  font-size: 19px;
  line-height: 22px;
  color: black;
  font-family: BMWGroupCondensed-Bold;
  margin-left: 0;
  text-align: center;
  padding-bottom: 7px;
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 90px;
  padding-bottom: 60px;
`;

export const Contact = styled.div``;
