import { logger } from 'common/logger/logger';
import { getQueryParams } from 'common/utils/getQueryParams';
import React, { useState } from 'react';
import useScript from 'react-script-hook';
import { QueryParams } from 'use-query-params';
import styled from 'styled-components';
import './styles.css';
import { generateViewerData } from './generateViewerData';

type QueryParams = {
  baseUrl?: string;
  ids?: string[] | string;
  damageName?: string;
};

const ViewerContainer = styled.div`
  max-width: 900px;
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImageViewer = () => {
  const [error, setError] = useState<string | void>();

  useScript({
    src: 'https://cdn.fyusion.com/0/sdk/web/prism/prism-viewer-1.0.0.min.js',
    onload: () => {
      const { baseUrl, ids: rawIds, damageName } = getQueryParams<QueryParams>();

      if (!baseUrl || !rawIds || rawIds.length === 0) {
        logger.error('dispayImages:invalidData', { baseUrl, ids: rawIds });
        setError('Invalid data');

        return;
      }

      const ids = Array.isArray(rawIds) ? rawIds : [rawIds];

      const viewerData = generateViewerData(baseUrl, ids, damageName);

      (window as any).SVFyusion?.addViewer(viewerData, 'viewer');
    }
  });

  return (
    <Container>
      {error ? (
        <div>{error}</div>
      ) : (
        <>
          <link
            rel="stylesheet"
            href="https://cdn.fyusion.com/0/sdk/web/prism/prism-viewer-1.0.0.css"
            type="text/css"
          />
          <ViewerContainer id="viewer" />
        </>
      )}
    </Container>
  );
};
