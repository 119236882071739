import React from 'react';
import styled from 'styled-components';

export const PretermReferencePage: React.FC = () => {
  return (
    <ReferencePageContainer>
      <Column>
        <Section>
          <SectionBody>
            <Text>
              To simplify the end-of-term inspection process, we’ve developed this form. It will allow customers to
              evaluate the vehicle’s condition prior to turn-in providing an estimate of excess wear and tear charges.
              The form includes the following sections:
            </Text>
            <SectionList columns={2}>
              <SectionListItem>Customer Information</SectionListItem>
              <SectionListItem>Body Assessment Charges</SectionListItem>
              <SectionListItem>Parts Charges</SectionListItem>
              <SectionListItem>Option Charges</SectionListItem>
              <SectionListItem>Excess Mileage Charge</SectionListItem>
              <SectionListItem>Summary of Charges</SectionListItem>
              <SectionListItem>Federal Odometer Disclosure Statement</SectionListItem>
            </SectionList>
            <Text>
              When the vehicle is turned in and the final inspection is completed, two parties must sign the form — the
              customer and a BMW retailer.
            </Text>
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>1. Customer Information</SectionHeader>
          <SectionBody>
            <Text>
              Please verify customer name, account number, year, model and Vehicle Identification Number of the vehicle.
            </Text>
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>2. Body Assessment</SectionHeader>
          <SectionBody>
            <Text>
              This section notes any dings, dents or scratches on the vehicle that will require repair. We’ve provided
              an inspection wheel that can be used during the assessment to determine which dents, dings or scratches
              may be subject to excess wear-and-tear charges. In order to assess each area accurately:
            </Text>
            <SectionList>
              <SectionListItem>
                Place the BMW Inspection Wheel over any dings, dents or scratches on the car. Any ding, dent or scratch
                over 2&quot; will be chargeable if not repaired.
              </SectionListItem>
              <SectionListItem>
                Any damage greater than 2&quot; but smaller than 4&quot; should be listed as a small charge.
              </SectionListItem>
              <SectionListItem>Any damage greater than 4&quot; should be listed as a large charge.</SectionListItem>
              <SectionListItem>Multiple instances of small damage should be listed.</SectionListItem>
              <SectionListItem>
                Multiple small charges can only be assessed up to the maximum allowed for each panel.
              </SectionListItem>
            </SectionList>
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>3. Parts Charges</SectionHeader>
          <SectionBody>
            <Text>
              After evaluating the exterior panels of the BMW, refer to the checklist in this section to identify the
              following parts damage.
            </Text>
            <SectionList>
              <SectionListItem>Any windshield scratch, crack or star.</SectionListItem>
              <SectionListItem>Mirror cover, housing and/or glass that is missing or damaged.</SectionListItem>
              <SectionListItem>
                Foglamps broken, cracked, missing or non-original manufacturer’s equipment.
              </SectionListItem>
              <SectionListItem>
                Lamp Assembly broken, cracked, missing or non-original manufacturer’s equipment.
              </SectionListItem>
              <SectionListItem>
                Headlamp broken, cracked, missing or non-original manufacturer’s equipment.
              </SectionListItem>
              <SectionListItem>Any missing keys. (A minimum of two are required.)</SectionListItem>
              <SectionListItem>
                Scheduled Maintenance not completed. (As determined via service engine light.)
              </SectionListItem>
              <SectionListItem>Any other missing or damaged parts must be inspected by a third-party.</SectionListItem>
            </SectionList>
          </SectionBody>
        </Section>
      </Column>
      <Column>
        <Section>
          <SectionHeader>4. Option Charges</SectionHeader>
          <SectionBody>
            <Text>
              Any other factory options that have not been identified in the sections above but are displayed on the
              second page of this inspection form will be charged if missing or damaged.
            </Text>
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>5. Excess Mileage Charge</SectionHeader>
          <SectionBody>
            <Text>
              Excess Mileage Charge should read: Excess mileage will be assessed upon final turn-in of the vehicle and
              based on the final odometer reading noted on the federal odometer disclosure statement.  If actual miles
              exceed the amount indicated on the contract, excess mileage will be charged at the applicable per mile
              rate as stated in the customer Contract.
            </Text>
          </SectionBody>
        </Section>
        <Section>
          <SectionHeader>6. Summary of Estimated Excess Wear-and-Use and Mileage Charges</SectionHeader>
          <SectionBody>
            <Text>
              Excess wear-and-use and mileage charges, if any, will consist of the body assessment total, parts total,
              options total, excess mileage charge and disposition fee. Note: Other end of term fees (e.g., property
              tax, unpaid tickets, etc.) may apply. Per the Contract, end of term charges may be subject to local and
              state taxes.
            </Text>
          </SectionBody>
        </Section>
      </Column>
    </ReferencePageContainer>
  );
};

const ReferencePageContainer = styled.div`
  break-before: page;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 25px;
  color: black;
  font-family: BMWGroupCondensed-Regular;
  margin-bottom: 15px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  flex: 0.47;
`;

const SectionList = styled.ul<{ columns?: number }>`
  columns: ${props => props.columns || 1};
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: ${props => (props.columns === 2 ? '30px' : '18px')};
`;

const SectionListItem = styled.li`
  font-size: 18px;
  line-height: 22px;
  color: black;
  font-family: BMWGroupCondensed-Regular;
  margin-bottom: 5px;
`;

const Section = styled.div``;

const SectionHeader = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: black;
  font-family: BMWGroupCondensed-Bold;
  margin-left: 0;
`;

const SectionBody = styled.div``;
