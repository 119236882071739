import React from 'react';
import styled from 'styled-components';
import { FooterContainer } from 'ewt/styles';

interface Props {
  content: string;
}

export const Footer: React.FC<Props> = ({ content }) => {
  return (
    <FooterContainer>
      <FooterText>{content}</FooterText>
    </FooterContainer>
  );
};

const FooterText = styled.div`
  color: #333333;
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
`;
