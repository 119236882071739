import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { VehicleInformation } from 'common/model/VehicleInformation';
import {
  PropertiesListItem,
  TitleText,
  ValueText,
  VehiclePropertiesListContainer
} from './vehiclePropertiesListStyles';
import { VinNumber } from './VinNumber';
import { Usage } from 'common/model/Usage';
import { Signature } from '../../leif/components/Signature';
import { CheckboxWithDescription } from './CheckboxWithDescription';
import { fetchDisclosureDocumentsByDisclosureId } from 'bmw/common/api/requests/fetchDisclosureDocumentsByDisclosureId';
import { DisclosureDocument, DisclosureDocumentType } from '../model/DisclosureDocument';
import { ScreenSize } from 'common/constants/ScreenSize';
import { commaNumber } from 'common/helpers/commaNumber';

interface Props {
  buildData: VehicleInformation | undefined;
  usage: Usage | undefined;
  disclosureId: string;
  currentMileage: number;
}

export const OdoStatement: React.FC<Props> = ({ buildData, usage, disclosureId, currentMileage }) => {
  const [disclosureDocument, setDisclosureDocument] = useState<DisclosureDocument>();

  useEffect(() => {
    const fetchOdoStatementData = async () => {
      const disclosureDocuments = await fetchDisclosureDocumentsByDisclosureId(disclosureId || '');

      const disclosureDocument = disclosureDocuments.find(
        document => document.type === DisclosureDocumentType.OdometerStatement
      );

      setDisclosureDocument(disclosureDocument);
    };
    fetchOdoStatementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OdoStatementContainer>
      <PageHeader>FEDERAL ODOMETER DISCLOSURE STATEMENT (VEHICLE)</PageHeader>
      <PageTitle>
        Federal law (and State law, if applicable) requires that the customer disclose the mileage to the company in
        connection with the transfer of ownership. Failure to complete or making a false statement may result in fines
        and/or imprisonment. Complete disclosure form below and return to company.
      </PageTitle>
      <Text>
        I, <ins>{usage?.contact?.firstName + ' ' + usage?.contact?.lastName}</ins> state that the odometer now reads{' '}
        <ins>{commaNumber(currentMileage)}</ins> miles and to the best of my knowledge that it reflects the actual
        mileage of the vehicle described below, unless one of the following statements is checked.
      </Text>

      {disclosureDocument?.odoExcess !== undefined && disclosureDocument.odoNotMileage !== undefined && (
        <>
          <CheckboxWithDescription
            checked={disclosureDocument.odoExcess}
            description="I hereby certify that to the best of my knowledge the odometer reading reflects the amount of mileage
                in excess of its mechanical limits."
          />
          <CheckboxWithDescription
            checked={disclosureDocument.odoNotMileage}
            description="I hereby certify that the odometer reading is NOT the actual mileage."
          />
        </>
      )}
      <SectionHeader>VEHICLE</SectionHeader>
      <VehiclePropertiesListContainer data-testid="vehicle-properties-list">
        <PropertiesListItem>
          <TitleText>YEAR</TitleText>
          <ValueText>{buildData?.year}</ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>MAKE</TitleText>
          <ValueText>{buildData?.make}</ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>MODEL</TitleText>
          <ValueText>{buildData?.styleName}</ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>BODY TYPE</TitleText>
          <ValueText>{buildData?.bodyTypePrimary}</ValueText>
        </PropertiesListItem>
        <PropertiesListItem>
          <TitleText>VIN</TitleText>
          <VinNumber vin={buildData?.vin || ''} isProtected />
        </PropertiesListItem>
      </VehiclePropertiesListContainer>

      <SectionHeader>CUSTOMER</SectionHeader>
      <VehiclePropertiesListContainer data-testid="vehicle-properties-list">
        <DetailsListItem>
          <TitleText>NAME</TitleText>
          <ValueText>{usage?.contact?.firstName + ' ' + usage?.contact?.lastName}</ValueText>
        </DetailsListItem>
      </VehiclePropertiesListContainer>
      <Signature
        signature={disclosureDocument?.clientSignature.replace('\n', '') || ''}
        signatureTitle="SIGNATURE"
        signatureDate={disclosureDocument?.clientSignatureDate || ''}
      />

      <SectionHeader>COMPANY</SectionHeader>
      <VehiclePropertiesListContainer data-testid="vehicle-properties-list">
        <DetailsListItem>
          <TitleText>NAME</TitleText>
          <ValueTextContainer>
            <ValueText>BMW FINANCIAL SERVICES, NA LLC</ValueText>
            <ValueText>FINANCIAL SERVICES VEHICLE TRUST</ValueText>
          </ValueTextContainer>
        </DetailsListItem>
        <DetailsListItem>
          <TitleText>ADDRESS</TitleText>
          <ValueTextContainer>
            <ValueText>1400 City View Drive</ValueText>
            <ValueText>Columbus, Ohio 43215</ValueText>
          </ValueTextContainer>
        </DetailsListItem>
      </VehiclePropertiesListContainer>
    </OdoStatementContainer>
  );
};

const OdoStatementContainer = styled.div`
  break-before: page;
  @media print {
    height: 100vh;
  }
`;

const PageHeader = styled.div`
  width: 100%;
  background-color: #444444;
  padding: 12px 10px;
  color: #ffffff;
  font-size: 21px;
  font-family: BMWGroupCondensed-Bold;
`;

const SectionHeader = styled.div`
  width: 100%;
  background-color: #eeefea;
  padding: 12px 10px;
  color: #444444;
  font-size: 21px;
  font-family: BMWGroupCondensed-Bold;
`;

const PageTitle = styled.div`
  font-size: 19px;
  padding: 12px 10px;
  color: black;
  font-family: BMWGroupCondensed-Bold;
  margin-bottom: 20px;
`;

const Text = styled.div`
  color: #666666;
  font-size: 19px;
  padding: 12px 10px;
  font-family: BMWGroupCondensed-Regular;
`;

const DetailsListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 20px 0px;
  width: 100%;

  @media print, screen and (min-width: ${ScreenSize.MobileLarge}px) {
    flex-direction: column;
    width: 50%;
    padding: 10px 0px 10px 0px;
    justify-content: flex-start;
  }
`;

const ValueTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${ScreenSize.MobileLarge}px) {
    align-items: flex-end;
  }
`;
