import { ChargeType } from './ChargeType';

export enum ChargesTypes {
  Preliminary = 'preliminary',
  Final = 'final'
}

export type InspectionChargesDetailsType = {
  totalPrice: number;
  type: ChargesTypes;
  charges: ChargeType[];
};
