import axios from 'axios';

import { Usage } from '../model/Usage';

export async function fetchUsageById(usageId?: string) {
  if (!usageId) {
    return;
  }
  const response = await axios.request<Usage>({
    method: 'get',
    url: `/usages/id/${usageId}`
  });
  return response.data;
}
