import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { customHeaderPrefix } from 'common/constants/customHeaderPrefix';
import { logger } from './logger';

export const logRequest = (response: AxiosResponse<unknown>) => {
  logger.info('httpRequest', {
    request: requestLogDetails(response.config),
    response: responseLogDetails(response),
    ...logDetails(response)
  });

  return response;
};

export const logError = (error: any) => {
  logger.error('httpRequest', {
    request: error.config && requestLogDetails(error.config),
    response: error.response && responseLogDetails(error.response),
    message: typeof error === 'string' ? error : error.message
  });

  throw error;
};

const logDetails = (response: AxiosResponse<unknown>) => ({
  requestId: response.config?.headers[`${customHeaderPrefix}request-id`]
});

const requestLogDetails = (config: AxiosRequestConfig) => ({
  url: config.url,
  params: config.params,
  method: config.method,
  data: tryParseJson(config.data)
});

const responseLogDetails = (response: AxiosResponse) => ({
  status: response.status,
  statusText: response.statusText,
  headers: response.headers,
  data: tryParseJson(response.data),
  duration: new Date().valueOf() - (response.config as any).metadata.startTime.valueOf()
});

const tryParseJson = (thing: any) => {
  try {
    return JSON.parse(thing);
  } catch (e) {
    return thing;
  }
};
