import React from 'react';
import styled from 'styled-components';

import { formatDate } from 'common/helpers/formatDate';

interface Props {
  signatureTitle: string;
  signature: string;
  signatureDate: string;
}

export const Signature: React.FC<Props> = ({ signatureTitle, signature, signatureDate }: Props) => {
  return (
    <SignatureContainer>
      <SignatureHeaderContainer>
        <SignatureText>{signatureTitle}</SignatureText>
        <SignatureText>DATE</SignatureText>
      </SignatureHeaderContainer>
      <SignatureContentContainer>
        <XIconContainer>
          <XIcon>X</XIcon>
        </XIconContainer>
        <ImagePreviewContainer>
          {signature && <ImagePreview src={`data:image/png;base64,${signature}`} />}
        </ImagePreviewContainer>
        <DateContainer>
          <DateWrapper>{formatDate(new Date(signatureDate))}</DateWrapper>
        </DateContainer>
      </SignatureContentContainer>
    </SignatureContainer>
  );
};

const SignatureContainer = styled.div`
  margin: 10px;
  border-bottom: 1px solid #666666;
  break-inside: avoid-page;
`;

const SignatureContentContainer = styled.div`
  display: flex
  flex-direction: row;
  justify-content: space-between;
`;

const XIconContainer = styled.div`
  flex: 0.05;
  justify-content: center;
  margin-left: 10px;
`;

const XIcon = styled.p`
  color: #ebb000;
  font-size: 22px;
  font-weight: 900;
`;

const SignatureHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SignatureText = styled.p`
  color: #666666;
  font-size: 16px;
  font-weight: 400;
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex: 0.75;
  justify-content: center;
  align-items: center;
  margin: 5px;
`;

const ImagePreview = styled.img`
  height: 150px;
`;

const DateContainer = styled.div`
  flex: 0.2;
  align-items: flex-start;
  text-align: right;
`;

const DateWrapper = styled.p`
  color: #666666;
  font-size: 16px;
  font-weight: 400;
`;
