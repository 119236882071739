import { stringify } from 'query-string';

export const getLinkForViewer = (links: string[], damageName: string) => {
  const splittedLink = links[0].split('/');
  splittedLink.pop();
  const baseUrl = splittedLink.join('/');

  const ids = links.map(link => link.slice(baseUrl.length + 1, link.length));

  const queryString = stringify({ baseUrl, ids, damageName }, { arrayFormat: 'comma' });

  return `display-images?${queryString}`;
};
